<template>
  <el-dialog :title="title" :modal-append-to-body="true" width="800px"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <div v-show="setp == 0">
      <div class="dialogHeight">
        <resume-upload ref="resumeUpload" :fetchUrl="fetchUrl" @upFileDate="upFileDate" :allowFiles="allowFiles"
                       :upFileSize="upFileSize" :dir="'resource'" :uploadNow="false"
                       @allFilesSuccessfully="allFilesSuccessfully()" :uploadProgressTypeShow="false"
                       :errorFileNoeDelete="false"
                       :hideUploadPlugin="true" :allowFilesShow="false"></resume-upload>
      </div>
      <div class="text_center">
        <el-button v-if="uploadCompleted" type="primary" size="small" @click="nextStep(0)">下一步</el-button>
      </div>
    </div>
    <div v-show="setp == 1">
      <div class="dialogHeight" v-loading="loading">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
          <template class="custom-tree-node" slot-scope="{ node, data }">
            <div class="flex_l_c">
              <el-image fit="contain" :src="require('@/assets/img/folder.png')"
                        class="itemImg"></el-image>
              <div style="padding-left: 10px">{{ node.label }}</div>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="flex_b_c">
        <el-button size="small" @click="addFolder()">新建文件夹</el-button>
        <div>
          <el-button type="primary" size="small" @click="nextStep(1)">确定</el-button>
          <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
      </div>
    </div>
    <div v-show="setp == 2">
      <el-result icon="success" title="保存成功！" subTitle="">
        <template slot="extra">
          <el-tooltip class="item" effect="dark" :content="promptText" placement="top">
            <div class="omit3">
              {{ promptText }}
            </div>
          </el-tooltip>
        </template>
      </el-result>
      <div class="text_center">
        <el-button size="small" @click="handleClose()">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  components: {ResumeUpload},
  name: "fileAdd",
  data() {
    return {
      title: '选择资源保存路径',
      visible: false,
      setp: 0,
      loading: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'folderName'
      },
      regex: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,
      fileType: '',
      selectFolder: {},
      fetchUrl: '',
      allowFiles: [],
      upFileSize: 2048,
      fileList: [],
      dataList: [],
      uploadCompleted: false,

      upDateAllFile: [],
      compressName: '',
      promptText: '',
    }
  },

  methods: {
    init(fileType, fileList, accumulation) {
      this.fileType = fileType
      this.fileList = fileList
      this.visible = true
      this.$nextTick(() => {
        this.selectFile(accumulation)
      })
    },

    selectFile(accumulation) {
      this.fetchUrl = ''
      if (this.fileType == '0') {
        this.fetchUrl = 'zyd-common/file/uploadThreeFile'
      }
      this.title = '正在上传文件'
      //添加文件到列表
      this.$refs.resumeUpload.up.addFile(this.fileList)
      //开始上传
      if (accumulation === 0) {

        setTimeout(() => {
          this.$refs.resumeUpload.uploadStart()
        }, 100)
      }
    },

    //所有文件上传完成
    allFilesSuccessfully() {
      this.uploadCompleted = true
      console.log('上传完成')
    },

    //获取所有文件夹
    getAllFolder() {
      this.loading = true
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkGetAllFolders, {}, 'post').then(data => {
        if (data && data.status) {
          this.data = data.data
          this.data.forEach(item => {
            this.$set(item, 'folderName', item.resourceName)
          })
        } else {
          this.$message.error(data.msg)
        }
        this.loading = false
      })
    },

    //选择文件夹
    handleNodeClick(data) {
      console.log(data)
      this.selectFolder = data
    },

    //新建文件夹
    addFolder() {
      if (!this.selectFolder.resourceId) {
        this.$message.warning('请选择要创建在哪个文件夹下')
        return
      }
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value || value.length == 0) {
            return '文件夹名称不能为空'
          }
          if (value.length > 60) {
            return '文件夹名称最长为60个字符'
          }
          if (!this.regex.test(value)) {
            return '文件夹名称不正确'
          }
        },
        inputErrorMessage: '文件夹名称不正确',
        inputPlaceholder: '请输入文件夹名称(限60字)',
      }).then(({value}) => {
        this.$axios(this.api.digital.comprehensiveResourcesFileLinkSave, {
          files: [],
          folderFlag: 0,
          folderName: value,
          parentId: this.selectFolder.id,
          resourceId: this.selectFolder.resourceId,
        }, 'post').then((res) => {
          if (res.status) {
            this.$message.success('添加成功')
            this.getAllFolder()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    //确定
    nextStep(num) {
      if (num == 0) {
        this.setp++;
        this.title = '选择文件保存路径'
        this.getAllFolder()
      }
      if (num == 1) {
        if (!this.selectFolder.resourceId) {
          this.$message.warning('请选择文件的保存位置')
          return
        }
        this.saveUpDate()
      }
    },

    //保存上传文件
    saveUpDate() {
      this.upDateAllFile.forEach(item=>{
        item.parentId = this.selectFolder.id
        item.resourceId = this.selectFolder.resourceId
      })
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkBatchSave, this.upDateAllFile, 'post').then((res) => {
        if (res.status) {
          this.setp++;
          this.title = '保存结果'
          let count = res.data.count
          let fileData = res.data.path
          this.promptText = `已保存 ${count} 份文件至：${fileData}`
          this.$emit('refreshList')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //获取上传文件
    upFileDate(data) {
      let row = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileBrowse', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      let files = []
      this.upDateAllFile = []
      console.log('===========')
      console.log(row)
      row.forEach(item1=>{
        if (this.fileType != 0) {
          files = [
            {
              fileFormat: item1.fileFormat,
              fileName: item1.fileName,
              fileSize: item1.fileSize,
              fileSizeFormat: item1.fileSizeFormat,
              url: item1.filePath,
              type: this.fileType,
            }
          ]
        } else {
          this.compressName = item1.compressName.split('.')[0]
          item1.fileList.forEach(item => {
            files.push(
                {
                  fileFormat: item.fileFormat,
                  fileName: item.fileName,
                  fileSize: item.fileSize,
                  fileSizeFormat: item.fileSizeFormat,
                  url: item.filePath,
                  type: this.fileType,
                }
            )
          })
        }
        this.upDateAllFile.push({
          files,
          folderFlag:1,
          folderName: this.fileType == 0 ? this.compressName : '',
          parentId: '',
          resourceId: '',
        })
      })
    },

    handleClose() {
      //清除上传文件
      this.$emit('deleteUploadedFiles')
      this.$refs.resumeUpload.clearAllFile()

      //数据重置
      this.upDateAllFile = []
      this.uploadCompleted = false
      this.setp = 0
      this.title = '正在上传文件'
      this.selectFolder = ''
      this.visible = false;
    },
  },
}
</script>

<style scoped>
.dialogHeight {
  height: 400px;
  overflow-y: auto;
}

.itemImg {
  height: 20px;
}

.upTishiao {
  margin-top: 10px;
  font-size: 10px;

  span {
    font-weight: bold;
  }
}
</style>